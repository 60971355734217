/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Toolbar = ({ model }) => (
  <div className="tool-bar">
    <div className="container">
      <nav className="navbar navbar-expand-sm bg-white navbar-tools mx-0 py-3 py-sm-3 px-0">
        <div className="container-fluid">

          <img className="h-40px d-inline-block d-sm-none" src="/Image/hacienda/logo-hacienda.svg" alt="Pagos Directos" title="Pagos Directos" />

          <div className="collapse navbar-collapse menu-tools justify-content-center" id="navbarSupportedContent">
            <ul className="navbar-nav mb-0">

              <li className="nav-item">
                <a className="nav-link " aria-current="page" href={model.urlHacienda}>Hacienda</a>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle active" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Pagos
                </a>

                <ul className="dropdown-menu multi-level shadow">
                  <li><a className="dropdown-item" href={model.urlPagosPersonas}>Personas</a></li>
                  <li><a className="dropdown-item" href={model.urlPagosEmpresas}>Empresas</a></li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li><a className="dropdown-item" href={model.urlPagoFolio}>En caja (Directos) </a></li>
                  <li className="dropdown-submenu">
                    <a className="dropdown-item" href="#">Express Vehícular</a>
                    <ul className="dropdown-menu shadow">
                      <li>
                        <a className="dropdown-item" tabIndex="-1" href={model.urlRevalidacion}>Revalidación</a>
                      </li>
                      <li>
                        <a className="dropdown-item" tabIndex="-1" href={model.urlSeguimiento}>
                          Seguimiento de
                          Guía
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li><a className="dropdown-item" href={model.urlRO}>Descarga de Recibos Oficiales</a></li>
                </ul>
              </li>

              <li className="nav-item">
                <a className="nav-link " aria-current="page" href={model.urlPagoFolio}>Pagos Directos</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" aria-current="page" href={model.urlFacturacion}>Facturación</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Validación
                </a>

                <ul className="dropdown-menu multi-level shadow">
                  <li><a className="dropdown-item" href={model.urlValidacionLicencia}>Validación de Licencias</a></li>
                  <li>
                    <a className="dropdown-item" href={model.urlValidacionActas}>Validación de Actas de Nacimiento</a>
                  </li>
                  <li><a className="dropdown-item" href="#">Otro </a></li>
                </ul>
              </li>
            </ul>

          </div>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="mdi mdi-menu" />
          </button>

        </div>
      </nav>
    </div>

  </div>
);

Toolbar.defaultProps = {
  model: {
    urlHacienda: 'https://hacienda.sonora.gob.mx/',
    urlPagosPersonas: 'https://hacienda.sonora.gob.mx/personas',
    urlPagosEmpresas: 'https://hacienda.sonora.gob.mx/empresas',
    urlPagoFolio: 'https://cuentaunica.siiafhacienda.gob.mx/Pase/PagoPorFolio',
    urlRevalidacion: 'https://cuentaunica.siiafhacienda.gob.mx/ExpressVehicular/Verificacion',
    urlSeguimiento: 'https://cuentaunica.siiafhacienda.gob.mx/Guia/Seguimiento',
    urlRO: 'https://cuentaunica.siiafhacienda.gob.mx/ReciboOficial/Busqueda',
    urlFacturacion: 'https://facturacion.siiafhacienda.gob.mx/',
    urlValidacionLicencia: 'https://verificalicenciadeconducir.sonora.gob.mx/Estatus/Captura',
    urlValidacionActas: 'https://registrocivil.sonora.gob.mx/validacion',
  },
};

export default Toolbar;
